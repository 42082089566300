<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="card">
      <div class="card-header">
        Reset Password
      </div>
      <div class="card-body">
        <p>You’re about to change your password with a password reset token. The password will be updated immediately.</p>
        <a-form-item
          label="New Secure Password"
          name="password"
          :rules="[
            { required: true, message: 'Password is required.' },
            { min: 6, message: 'Password must be at least 6 characters.' },
          ]"
        >
          <a-input
            v-model:value="values.password"
            name="password"
            type="password"
          />
        </a-form-item>
        <a-form-item
          label="Confirm Password"
          name="passwordConfirm"
          :rules="[
            { required: true, message: 'Password confirmation is required.' },
            { min: 6, message: 'Password must be at least 6 characters.' },
          ]"
        >
          <a-input
            v-model:value="values.passwordConfirm"
            name="passwordConfirm"
            type="password"
          />
        </a-form-item>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading">
          Reset
        </a-button>
        <a-button type="link" @click="$router.push({ name: 'login' })">
          Back to login
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      values: {
        password: null,
        passwordConfirm: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/me/password/${ this.$route.params.id }`, values)

        this.$store.commit('user', data)

        this.$message.success('Password has been reset.')
        this.$router.push({ name: 'index' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
